<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="page_links">
					当前位置：
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/uc_order' }">订单管理</el-breadcrumb-item>
						<el-breadcrumb-item>订单详情</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="order_status">
					<div class="status_wrap">
						<p class="txt">当前订单状态</p>
						<p class="status">售后中</p>
					</div>
					<div class="detail_wrap">
						<el-descriptions labelClassName="la_style" contentClassName="con_style" :colon="false">
							<el-descriptions-item label="订单号">4962990566310610271</el-descriptions-item>
							<el-descriptions-item label="下单时间">2022/08/05 00:56:11</el-descriptions-item>
							<el-descriptions-item label="订单类型">普通订单</el-descriptions-item>
							<el-descriptions-item label="付款时间">2022/08/05 00:56:11</el-descriptions-item>
							<el-descriptions-item label="完成时间">2022/08/05 00:56:11</el-descriptions-item>
							<el-descriptions-item label="支付方式">银行卡</el-descriptions-item>
							<el-descriptions-item label="商家备注">鲸吞下单#采购金额：￥23.02#毛利：￥0.54#利润率：2.29%</el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
				<div class="order_infos">
					<div class="info_con">
						<el-descriptions title="收货信息" labelClassName="la_style" contentClassName="con_style" 
						:colon="false" :column=1>
							<el-descriptions-item label="收货人">林**</el-descriptions-item>
							<el-descriptions-item label="手机号码">15*******63</el-descriptions-item>
							<el-descriptions-item label="收货地址">福建省 泉州市 洛江区  福建省泉州市洛江区马甲镇***</el-descriptions-item>
						</el-descriptions>
					</div>
					<div class="info_con">
						<el-descriptions title="物流信息" labelClassName="la_style" contentClassName="con_style" 
						:colon="false" :column=1 >
							<el-descriptions-item label="快递公司">极兔快递</el-descriptions-item>
							<el-descriptions-item label="物流单号">4962990566310610271</el-descriptions-item>
							<el-descriptions-item label="发货时间">2022/08/05 00:56:11</el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
				<div class="order_infos">
					<div class="info_con">
						<el-descriptions title="退货信息" labelClassName="la_style" contentClassName="con_style" 
						:colon="false">
							<el-descriptions-item label="快递公司">圆通快递</el-descriptions-item>
							<el-descriptions-item label="快递单号">4962990566310610271</el-descriptions-item>
							<el-descriptions-item label="退货地址">福建省 泉州市 洛江区  福建省泉州市洛江区马甲镇***</el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
				<div class="order_goods">
					<p class="fb">商品信息</p>
					<div class="table_wrap">
						<el-table :data="list">
							<el-table-column label="货品信息" width="400" prop="scope">
								<template slot-scope="scope">
									<div class="goods_wrap">
										<div class="img_box"><img class="coverimg" :src="scope.row.pic" /></div>
										<div class="con_box">
											<p class="name">{{scope.row.name}}</p>
											<p class="color">{{scope.row.color}}</p>
											<p class="bianma">{{scope.row.bianma}}</p>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="售价/数量" prop="scope" width="140" class="c_price">
								<template slot-scope="scope">
									<p>{{scope.row.price}}</p>
									<p class="num">x{{scope.row.num}}</p>
								</template>
							</el-table-column>
							<el-table-column label="售后状态" prop="status" width="200"></el-table-column>
							<el-table-column label="采购价" prop="price1" width="100"></el-table-column>
							<el-table-column label="商品总价" prop="price2" width="100"></el-table-column>
							<el-table-column label="优惠明细" prop="youhui"></el-table-column>
						</el-table>
					</div>
					<div class="total_txt">
						<p>商品总价运费: ￥0.00 优惠: ￥0.00<span> 应付:<em>￥53.80</em></span></p>
						<p>(实付:￥53.72， 支付优惠:￥0.08)</p>
					</div>
				</div>
				<div class="order_records">
					<p>协商记录</p>
					<div class="records_wrap">
						<div class="record_con c">
							<p class="txt">发起了退款申请，等待商家处理</p>
							<div class="txt_wrap">
								<el-descriptions labelClassName="la_style" contentClassName="con_style" :colon="false">
									<el-descriptions-item label="操作时间">2022/02/24  13:55:32</el-descriptions-item>
									<el-descriptions-item label="操作人">买家</el-descriptions-item>
									<el-descriptions-item label="售后类型">仅退款</el-descriptions-item>
									<el-descriptions-item label="货物状态">未收到货</el-descriptions-item>
									<el-descriptions-item label="退款原因">系统签收商品未收到</el-descriptions-item>
									<el-descriptions-item label="退款金额">￥1.1</el-descriptions-item>
									<el-descriptions-item label="退款说明">商家发虚假物流</el-descriptions-item>
								</el-descriptions>
							</div>
						</div>
						<div class="record_con b">
							<p class="txt">同意退款给买家</p>
							<div class="txt_wrap">
								<el-descriptions labelClassName="la_style" contentClassName="con_style" :colon="false">
									<el-descriptions-item label="操作时间">2022/02/24  13:55:32</el-descriptions-item>
									<el-descriptions-item label="操作人">商家 - 谢先生</el-descriptions-item>
									<el-descriptions-item label="退款金额">￥1.1</el-descriptions-item>
								</el-descriptions>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
export default {
	name: "orderDetailAfterSale",
	data() {
		return {
			list:[
				{
					pic:'https://gw.alicdn.com/imgextra/i1/2322239497/O1CN014dEs522K1gBPlY4Tr_!!2322239497-0-alimamacc.jpg_300x300q90.jpg_.webp',
					name:'2022秋冬新毛毛拖鞋女韩版卡通EVA包头轻便保暖棉拖鞋居家外穿拖',
					color:'米白色，36-37码',
					bianma:'商家编码：dy-33286485410',
					price:'26.9',
					num:'1',
					status:'--',
					price1:'￥23.98',
					price2:'￥23.98',
					youhui:'无优惠'
				},
				{
					pic:'//gw.alicdn.com/bao/uploaded/i4/802747447/O1CN01Q0QhSq24smBAAkza8_!!802747447.jpg_300x300q90.jpg_.webp',
					name:'2022秋冬新毛毛拖鞋女韩版卡通EVA包头轻便保暖棉拖鞋居家外穿拖',
					color:'米白色，36-37码',
					bianma:'商家编码：dy-33286485410',
					price:'26.9',
					num:'1',
					status:'--',
					price1:'￥23.98',
					price2:'￥23.98',
					youhui:'无优惠'
				}
			]
			
		}
	},
	components:{
		myHead,myFoot
	},
	created(){
		
	},
	methods: {
		
	}
}
</script>

<style scoped>
.order_status{margin-top: 30px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px 20px;background: #fff;}
.order_status .status_wrap{line-height: 1;padding-bottom: 30px;border-bottom: 1px solid #eee;}
.order_status .status_wrap .txt{font-size: 14px;color: #999999;}
.order_status .status_wrap .status{font-size: 24px;margin-top: 16px;}
.order_status .detail_wrap{padding-top: 30px;}
.order_infos{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 0;background: #fff;display: flex;}
.order_infos .info_con{flex: 1;margin-left: 40px;width: 0;box-sizing: border-box;border-right: 1px solid #eee;}
.order_infos .info_con:last-child{border-right: none;}
.order_goods{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px;background: #fff;line-height: 1;}
.order_goods .table_wrap{margin-top: 30px;font-size: 12px;}
.goods_wrap{width: 100%;box-sizing: border-box;display: flex;}
.goods_wrap .img_box{width: 48px;height: 48px;overflow: hidden;background: #eee;}
.goods_wrap .img_box>img{width: 100%;height: 100%;object-fit: cover;}
.goods_wrap .con_box{width: 200px;margin-left: 10px;}
.goods_wrap .con_box .name{line-height: 16px;color: #333;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.goods_wrap .con_box .color{color: #999999;margin-top: 10px;line-height: 1;}
.goods_wrap .con_box .bianma{color: #999999;margin-top: 6px;line-height: 1;}
.order_goods .total_txt{display: flex;justify-content: flex-end;flex-direction: column;font-size: 12px;color: #999999;align-items: flex-end;padding: 26px 0 15px;}
.order_goods .total_txt span{font-size: 16px;color: #333;}
.order_goods .total_txt span>em{color: var(--red);font-weight: bold;}
.order_goods .total_txt>p:nth-child(2){margin-top: 10px;}
.order_records{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px;background: #fff;line-height: 1;}
.records_wrap{margin-top: 25px;}
.records_wrap .record_con{padding-left: 42px;position: relative;}
.record_con::before{width: 32px;height: 32px;border-radius: 50%;display: flex;justify-content: center;align-items: center;position: absolute;top: 0;left: 0;z-index: 2;font-size: 12px;font-weight: bold}
.record_con.c{padding-bottom: 13px;}
.record_con.c::before{content: '买家';background: #ffecee;color: var(--red);}
.record_con.b::before{content: '商家';background: #f0ffee;color: #0e9f1f;}
.record_con.c::after{content: '';width: 2px;height: 68%;background: #eee;position: absolute;left: 15px;top: 40px;}
.record_con .txt{font-size: 14px;line-height: 32px;}
.record_con .txt_wrap{margin-top: 12px;width: 900px;}
</style>
<style>

.page_links{line-height: 1;font-size: 14px;color: #999999;display: flex;margin-top: 25px;}
.page_links .el-breadcrumb__inner{font-weight: bold !important;color: #333 !important;}
.page_links .el-breadcrumb__inner.is-link{font-weight: initial !important;color: #999 !important;}
.page_links .el-breadcrumb__inner.is-link:hover{color: #f0423f !important;text-decoration: underline;}

.con_style{color: #333;}
.la_style{color: #999;width: 70px;}
.el-descriptions{font-size: 12px;}
.el-descriptions__title{color: #333;}

.el-table .el-table_1_column_2 .cell .num{color: #999;}
.el-table th.el-table__cell{background: #fff6f7;border-bottom: none !important;color: #666666;padding: 12px 0;}
.el-table .el-table__cell{vertical-align: top;padding: 24px 0;}
.el-table .cell{padding: 0 20px;text-align: center;font-size: 12px;color: #333;}
.el-table th.el-table__cell>.cell{padding: 0 20px;}
.el-table .el-table_1_column_1 .cell{text-align: left;}
.el-table .el-table_1_column_2 .cell{text-align: right;}
.el-table th.el-table_1_column_1 .cell{text-align: left;}
.el-table th.el-table_1_column_2 .cell{text-align: right;}


</style>